/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e6eaf3 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(195, 192, 192);
}

/* grid table*/
.css-de9k3v-MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
}

/* .css-1j9kmqg-MuiDataGrid-toolbarContainer {
  float: right !important;
} */
@media (min-width: 600px) {
  .css-de9k3v-MuiDataGrid-selectedRowCount {
    visibility: hidden !important;
  }
}
/* grid table*/

/* btn next pre */
.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  border-radius: 50% !important;
  width: 30px !important;
  height: 30px !important;
}
.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  margin-left: 5px !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  margin-top: 16px !important;
}
.css-f3jnds-MuiDataGrid-columnHeaders {
  min-height: 40px !important;
  max-height: 40px !important;

  line-height: 40px !important;
  border-top: 1px solid #ccc !important;
  border-radius: 0 !important;
}

/* .css-1w5m2wr-MuiDataGrid-virtualScroller {
  margin-top: 44px !important;
} */

/* .MuiDataGrid-virtualScroller {
  height: 277px !important;
} */

p {
  font-size: 12px !important;
}
h5 {
  font-size: 16px !important;
  margin: 0 !important;
}
.offcanvas {
  transition: transform 0.8s ease-in-out !important;
}
/* admin css */
.btn {
  font-size: 13px !important;
}
/* admin css */

.dropdown-menu {
  display: block !important;
  visibility: hidden !important;
  opacity: 0 !important;
  transform: translateY(50px) !important;
  transition: 0.5s ease all !important;
}
.dropdown-menu.show {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
  transform: translateY(0px) !important;
  transition: 0.5s ease all !important;
}

.css-jj6133 {
  height: 428px !important;
}
button.btn_submit:nth-child(2) {
  background-color: #224530 !important;
}

.Pending {
  color: #ecd71cef !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
}
.Pending::before {
  content: "";
  background-color: #ecd71cef !important;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.Approved {
  color: #25af19ef !important;
  text-transform: uppercase !important;

  pointer-events: none;
  font-weight: 500 !important;
}
.Approved::before {
  content: "";
  background-color: #25af19ef !important;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.Rejected {
  color: #df272def !important;
  pointer-events: none;
  font-weight: 500 !important;
  text-transform: uppercase !important;
}

.Rejected::before {
  content: "";
  background-color: #df272def !important;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.jj {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.app_check .bi {
  color: #fff !important;
  font-size: 15px !important;
}
.MuiDataGrid-virtualScroller.css-1w5m2wr-MuiDataGrid-virtualScroller {
  margin-top: 56px !important;
}

.bi-plus {
  color: #000 !important;
}
.table-cell-trucate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.modal.fade .modal-dialog {
  transition: transform 0.8s ease-out !important;
}

.MuiTooltip-tooltip,
.MuiTooltip-arrow::before {
  background-color: #224530 !important;
}

.css-ja5taz-MuiTooltip-tooltip {
  font-size: 14px !important;
}
.MuiDataGrid-main.css-204u17-MuiDataGrid-main {
  border-top: 1px solid #cccccc88 !important;
}

.bounce-vertically-90:nth-child(2) {
  background: #d56928 !important ;
}
.bounce-vertically-90:nth-child(1) {
  background: #2f5888 !important ;
}
.bounce-vertically-90:nth-child(3) {
  background: #224530 !important ;
}
.check-control {
  top: 8px !important;
  position: relative !important;
  margin-left: 10px !important;
  width: 16px !important;
}

.select:disabled {
  cursor: not-allowed !important;
}
.time_sheet {
  display: flex !important;
  justify-content: center !important;
}
.time_sheet .project_selectbox {
  border-radius: 30px 0 0 30px !important;
}
.select_box {
  border-radius: 0 !important;
}
.search_btn {
  border-radius: 0 30px 30px 0 !important;
  background: #2f5888 !important;
  color: #fff !important;
}

.active {
  background-color: #e3e3e3e3 !important;
}
.multi_select {
  width: 100% !important;
}

.css-1jqq78o-placeholder {
  font-size: 12px !important;
}
