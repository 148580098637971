@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&family=Poppins:wght@400;500&family=Roboto:wght@300;400;500&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: none !important;
}

.navbar-brand img {
  width: 50%;
}
.btn_botm {
  position: absolute;
  bottom: -62px;
  left: 0;
  z-index: 999;
}

.fixex_foo {
  position: fixed;
  bottom: 0;
  background-color: #f2f2f2;
  padding: 10px !important;
  /* border-top: 1px solid #d56928 ; */
  box-shadow: 0px -1px 2px #d56928;
}
/* footer section */

.footer-copyright {
  padding: 5px 0;
}
/* footer section */

/* sidebar section */
.sidebar-link {
  display: flex !important;
  align-items: center !important;
}
.sidebar-link .right-icon {
  display: inline-flex !important;
  transition: 0.5s all;
}

.sidebar-link[aria-expanded="true"] .right-icon {
  transform: rotate(180deg);
}

.line_btn {
  border-bottom: 1px solid #ccc !important;
  transition: 0.5s all ease-in-out;
  position: relative;
  /* padding-bottom: 3px; */
  text-align: center;
}
.text_add .font_sm {
  font-size: 10px;
}

.line_btn:hover {
  background-color: #f1f1f1 !important;
}
.line_btns:hover {
  background-color: #f1f1f1 !important;
}
.line_btns {
  transition: 0.5s all ease-in-out;
  border-top: 1px solid #ccc !important;
  text-align: center !important;
}
.sidebar-nav {
  width: 68px !important;
}
.side_icon i.bi {
  font-size: 16px !important;
  color: #d56928 !important;
}
.dash_bd {
  padding-top: 6.2px !important;
}

.offcanvas.offcanvas-start {
  border-right: none !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.p_bottom {
  padding-bottom: 70px !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none !important;
  border-radius: 0.25rem;
}
.btn_col {
  background-color: #d56928 !important;
}

.reload_text .bi {
  color: #2f5888 !important;
  font-size: 14px !important;
}

.card-headers h5 {
  color: #2f5888 !important;
}
.offcanvas-title {
  color: #2f5888 !important;
}
.ref_txt {
  padding-left: 4px !important;
  color: #2f5888 !important;
  font-size: 17px !important;
}
.btn_submit {
  padding: 4px 10px !important;
}
.btn_submit .bi-plus-lg {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #fff !important;
}
.navbar-toggler .bi-text-right {
  color: #000 !important;
}
.navbar-toggler .bi-text-left {
  color: #000 !important;
}
/* .btn_submit span {
  font-size: 14px !important;
} */
.btn-success {
  background-color: #d56928 !important;
}
.btn-primary {
  background-color: #224530 !important;
}
.btn-danger {
  background-color: #d22b2b !important;
}
@media (min-width: 991px) {
  body {
    overflow: auto !important;
  }

  .offcanvas-backdrop::before {
    display: none !important;
  }
  .sidebar-nav {
    transform: none !important;
    visibility: visible !important;
    top: 58px;
    height: 100%;
  }

  .main-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .card_top {
    padding-top: 53px;
  }
}
@media (max-width: 991px) {
  .card_top {
    margin-top: 53px;
  }
  .search {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .card_top {
    margin-top: 53px;
  }
  .card {
    margin-top: 38px !important;
    margin-bottom: 60px !important;
  }
  .p_bottom {
    padding-bottom: 10px !important;
  }
  .main_div {
    overflow-x: scroll !important;
  }
  .offcanvas-backdrop.show {
    opacity: 0 !important;
  }
  .offcanvas.offcanvas-start {
    z-index: 9999 !important;
  }
}

/* sidebar section */

/* page Section  */

.card .card-headers {
  background: #f2f2f2;
  border-bottom: none;
  padding: 10px;

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.card .card-header {
  background: #f2f2f2;

  border-bottom: none;
  padding: 10px;

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.offcanvas.offcanvas-end {
  /* width: 22% !important; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: none !important;
}
.cards {
  padding: 10px;
}
.bottom_addbtns {
  position: fixed;
  bottom: 11px;
  background: #f2f2f2;
  padding: 12.5px 0;
  margin-bottom: -10px;
  border-top: 1.4px solid #d56928 !important;
}

.bottom_addbtn {
  margin-top: 24.4rem !important;
}
.offcanvas.canvas_width {
  width: 80% !important;
}
.offcanvas-body {
  padding: 0 !important;
}
/* page Section  */

/* tabs section */
.bi-dot {
  font-size: 15px !important;
}

.masters {
  text-align: center;
}
.tab_font span {
  font-size: 9.5px !important;
}
/* tabs section */

.custom-scrollbar::-webkit-scrollbar {
  width: 4px !important;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #b6a2968a !important;
}
.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e8ebf2 !important;
}

/* button section start */
.btn-success,
.btn-primary,
.btn-warning,
.btn-danger {
  --bs-btn-border-color: none !important;

  --bs-btn-focus-shadow-rgb: none !important;

  --bs-btn-active-shadow: none !important;
}

.search_box {
  box-shadow: none !important;
}

.modal-header {
  border: none !important;
}

.modal-content {
  background-color: #f2f2f2 !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  width: 100% !important;
  color: #000 !important;
  background-color: #b8bbbdbb !important;
}
.offcanvas-title {
  font-size: 15px !important;
}
/* reset buttons */
.reset {
  background-color: rgba(240, 248, 255, 0.658) !important;
  margin-left: 10px !important;
  color: black !important;
  padding: 4px 20px !important;
}
/* reset buttons */

/* validation */
.error {
  color: rgb(207, 47, 47);
  font-size: 12px;
  margin-top: 5px !important;
}
/* validation */

/* input box css */
.per_btn {
  position: absolute;
  bottom: 11px;
}
.form-group label {
  font-size: 12px !important;
  line-height: 35px !important;
}
.form-label {
  font-size: 12px !important;
}
label {
  display: unset !important;
}
.offcanvas-backdrop {
  z-index: 1045 !important;
}
.form-control {
  font-size: 12px !important;
  border: 1px solid #ccc !important;
  height: 32px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 4px !important;
}

.form-select {
  font-size: 10px !important;
  width: 100% !important;
  height: 32px !important;
  /* border-radius: 1rem !important; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  right: 0 !important;
}
#exampleInputEmail1 {
  font-size: 13px !important;
}
.empl_top input {
  height: 27px !important;
  font-size: 12px !important;
  width: 100% !important;
}
input {
  height: 25px !important;
  width: 100%;
  font-size: 12px !important;
  outline: none !important;
  border: none !important;
  box-shadow: unset !important;
}

.accordion-button {
  outline: none !important;
  border: none !important;
  box-shadow: unset !important;
}

.card-body {
  padding: 0 !important;
  margin-top: 4px;
}

.card-header h4 {
  font-size: 20px !important;
  padding-bottom: 0;
  margin-bottom: 0;
}
/* input box css */

.alert {
  padding: 8px !important;
}
.offcanvas-header .btn-close {
  font-size: 9px !important;
  background-color: #ccc !important;
  border-radius: 1rem !important;
  position: absolute;
  right: 16px;
  outline: none !important;
  box-shadow: none !important;

  opacity: 1 !important;
}
.offcanvas-header {
  background: #f2f2f2 !important;
  /* box-shadow: 0 0.9px 1px #d56928 !important; */
  border-bottom: 1.4px solid #d56928 !important;
  padding: 9.5px !important;
}
/* input box css */
/* offcanvas width */
.offcanvas.off_full {
  width: 80% !important;
}
/* offcanvas width */

.reruired_field {
  color: red !important;
}

.top {
  padding-top: 20px !important;
}

button.reload_text {
  background-color: #2f5888 !important;
  outline: none !important;
  border: unset !important;
  box-shadow: none !important;
  position: absolute !important;
  right: 115px !important;
  padding: 4px 10px;
}
.reload_bt {
  background-color: #2f5888 !important;
  outline: none !important;
  border: unset !important;
  box-shadow: none !important;

  padding: 1px 8px !important;
  border-radius: 5px !important;
}
.reload_text .bi-arrow-clockwise {
  color: #fff !important;
  font-size: 14px;
  font-style: normal !important;
}
.btnrt .red_bt {
  position: unset !important
  ;
}

@media (max-width: 991px) {
  .offcanvas.offcanvas-end {
    width: 100% !important;
  }
}
